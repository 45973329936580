import { Component } from 'react'
import PropTypes from 'prop-types'

import sendEvent from '../../../helpers/sendAnalytics';

class EventTracker extends Component {
  componentWillMount() {
    const { gtag, ga, fb, appnexus, snapchat } = this.props

    // EVENT: MULTIPLE
    sendEvent({
      gtag,
      ga,
      fb,
      appnexus,
      snapchat
    })
  }

  render() {
    return null
  }
}

EventTracker.propTypes = {
  gtag: PropTypes.objectOf({
    send_to: PropTypes.string
  }),
  ga: PropTypes.objectOf({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string,
    hitCallback: PropTypes.function
  }),
  fb: PropTypes.string,
  appnexus: PropTypes.string,
  snapchat: PropTypes.string
}

EventTracker.defaultProps = {
  gtag: {},
  ga: {},
  appnexus: '',
  fb: '',
  snapchat: ''
}

export default EventTracker
