import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled.div`
  margin: 0 auto;
  width: ${props => (typeof props.width === 'string') ? props.width : `${props.width}px`};
  max-width: 100%;

  @media (max-width: 1280px) {
    width: 100%;
    ${tw`px-5`}
  }
`

Container.propTypes = {
  width: PropTypes.string
}

Container.defaultProps = {
  width: "1240px"
}

export default Container
